import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormControl } from '@mui/material';
import { Success } from '../../shared/ui/icons/core/Success';
import { StepButton } from '../../shared/ui/components/StepButton';
import { removeZeros } from '../../shared/utils/serviceUtils/validationUtils';
import { Declined } from '../../shared/ui/icons/core/Declined';
import { Pending } from '../../shared/ui/icons/core/Pending';
import { colors } from '../../shared/ui/constants/styleConstants';
import { CopyIcon } from '../../shared/ui/icons/core/CopyIcon';
import CustomSwitch from '../../shared/ui/components/Switch';
import { createUDPRequest } from '../../services/workki/TransactionsActions';
import { useDispatch } from 'react-redux';
import saveAs from 'file-saver';
import { getPaymentDocument } from '../../entities/Payments/api/DepositsActions';

const OperationTopBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  font-weight: 300;
  align-items: center;
  & > p {
    margin: 0;
  }
`;

const OperationInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  font-weight: 300;
  align-items: center;
  & > p {
    margin: 0;
  }
`;

const OperationSecondaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 14px;
  align-items: center;
  color: #8f9295;
  & > p {
    font-weight: 300;
    margin: 0;
  }
`;
const UPDWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 300px;
`;
const UPDBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: start;
  & > p {
    color: ${colors.workkiDarkMain};
    font-weight: 400;
    margin: 0;
  }
`;
const UPDCaption = styled.p`
  margin: 4px 0 0 0;
  font-size: 12px;
  font-weight: 400;
  text-align: start;
  color: ${colors.workkiDarkSecondary};
`;
const OperationCancelInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 14px;
  align-items: center;
  color: #8f9295;
  & > p:first-of-type {
    font-weight: 300;
    color: ${colors.workkiDarkMain};
    margin: 0 0 4px 0;
  }
  & > p:nth-of-type(2) {
    font-weight: 300;
    color: ${colors.workkiDarkMiddle};
    margin: 0 0 2px 0;
  }
  & > div {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    & > p {
      font-weight: 400;
      :hover {
        color: ${colors.workkiDarkMiddle};
      }
      color: ${colors.workkiDarkSecondary};
      margin: 0 0 2px 0;
    }
  }
`;

const Amount = styled.p`
  font-weight: 500;
  color: #24272a;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
`;

const Service = styled.p`
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

const OperationTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
`;

const PaymentInfo = ({ operationData, handleClose, saveDocument }) => {
  const [checked, setChecked] = useState(false);
  const [showCaption, setShowCaption] = useState(false);
  const dispatch = useDispatch();
  const handleSwitchChange = event => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setShowCaption(true);
      dispatch(
        createUDPRequest({
          id: operationData.additional_id,
          is_need_document: true
        })
      );
    } else {
      setShowCaption(false);
    }
  };
  const renderStatusTitle = loadingStatus => {
    const content = {
      'Новый': () => {
        return 'Операция в обработке';
      },
      'В процессе': () => {
        return 'Операция в обработке';
      },
      'Отменен': () => {
        return 'Операция отменена';
      },
      'Выполнен': () => {
        return 'Успешное пополнение';
      }
    };

    return content[loadingStatus] ? content[loadingStatus]() : '';
  };
  const renderStatusIcon = loadingStatus => {
    const content = {
      'Новый': () => {
        return <Pending defaultColor={'#C8C9CA'} spacing={'0'} />;
      },
      'В процессе': () => {
        return <Pending defaultColor={'#C8C9CA'} spacing={'0'} />;
      },
      'Отменен': () => {
        return <Declined defaultColor={'#DB4344'} spacing={'0'} />;
      },
      'Выполнен': () => {
        return <Success defaultColor={'#00A676'} spacing={'0'} />;
      }
    };

    return content[loadingStatus] ? content[loadingStatus]() : '';
  };
  const copyTextToClipboard = async text => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {}
  };
  useEffect(() => {
    if (operationData && operationData.is_need_document) {
      setChecked(true);
      setShowCaption(true);
    }
  }, [operationData]);
  const getDocument = link => {
    const id = link ? link.split('=')[1] : '';
    dispatch(getPaymentDocument(id))
      .then(response => {
        console.log(response);
        if (response.data instanceof Blob) {
          const fileName = 'Платежный документ';
          saveAs(response.data, fileName);
        } else {
          console.error('Ответ не является Blob');
        }
      })
      .catch(error => {
        console.error('Ошибка при скачивании файла:', error);
      });
  };

  return (
    <>
      <OperationTopBlock>
        {operationData.payment
          ? renderStatusIcon(operationData.status)
          : operationData.operationType === 'transaction' && <Success defaultColor={'#00A676'} spacing={'0'} />}
        <OperationTitle>
          {operationData.operationType === 'deposit'
            ? operationData.payment_system_id === 3
              ? 'Счёт сформирован'
              : renderStatusTitle(operationData.status)
            : 'Операция выполнена'}
        </OperationTitle>
      </OperationTopBlock>
      {operationData && operationData.operationType !== 'transaction' && (
        <>
          <OperationInfoBlock>
            <Amount>
              {operationData.payment_system_id === 3 ? '' : '+'} {removeZeros(+operationData.amount)} ₽
            </Amount>
            {operationData.status !== 'Отменен' && (
              <Service>{operationData.payment ? operationData.payment.t_name : ''}</Service>
            )}
          </OperationInfoBlock>
          {operationData && operationData.status !== 'Отменен' ? (
            <OperationInfoBlock>
              <OperationSecondaryInfo>
                <p>{operationData.is_security_payment === 1 ? 'На обеспечительный баланс' : 'На общий баланс'}</p>
                <p>{operationData.created_at}</p>
                <p>ID платежа: #{operationData.id}</p>
              </OperationSecondaryInfo>
            </OperationInfoBlock>
          ) : (
            <OperationInfoBlock>
              {operationData && (
                <OperationCancelInfo>
                  <p>Код операции</p>
                  <p>{operationData.pay_system_order_id}</p>
                  <div onClick={() => copyTextToClipboard(operationData.pay_system_order_id)}>
                    <CopyIcon
                      defaultColor={colors.workkiDarkSecondary}
                      spacing={'2px'}
                      hoverColor={colors.workkiDarkMiddle}
                    />
                    <p>Скопировать</p>
                  </div>
                </OperationCancelInfo>
              )}
            </OperationInfoBlock>
          )}
        </>
      )}
      {operationData && operationData.operationType === 'transaction' && (
        <>
          <OperationInfoBlock>
            <Amount>-{removeZeros(+operationData.amount)} ₽</Amount>
          </OperationInfoBlock>
          <OperationInfoBlock>
            <OperationSecondaryInfo>
              <p>{operationData.created_at}</p>
              <p>ID платежа: #{operationData.service_request_id}</p>
            </OperationSecondaryInfo>
            <UPDWrapper>
              {operationData.document_link ? (
                <FormControl fullWidth={true}>
                  <StepButton
                    type='outlined'
                    spacing={'0'}
                    title={'Скачать УПД'}
                    handleAction={() => getDocument(operationData.document_link)}
                  ></StepButton>
                </FormControl>
              ) : (
                <>
                  <UPDBlock>
                    <CustomSwitch checked={checked} onChange={handleSwitchChange} spacing='8px' />
                    <p>Сформировать универсальный передаточный документ</p>
                  </UPDBlock>
                  {showCaption && (
                    <UPDCaption>УПД будет сформирован в течение 5 рабочих дней и прикреплён в операцию</UPDCaption>
                  )}
                </>
              )}
            </UPDWrapper>
          </OperationInfoBlock>
        </>
      )}
      <FormControl fullWidth={true}>
        {operationData && operationData.operationType !== 'transaction' && operationData.payment_system_id === 3 && (
          <StepButton
            type={'outlined'}
            title={'Скачать счет'}
            handleAction={() => {
              saveDocument(operationData, 'bill');
              handleClose();
            }}
          />
        )}
        {operationData && operationData.operationType !== 'transaction' && operationData.status === 'Выполнен' && (
          <StepButton
            type={'outlined'}
            title={'Скачать чек'}
            handleAction={() => {
              saveDocument(operationData, 'card');
              handleClose();
            }}
          />
        )}
        {operationData &&
          operationData.operationType !== 'transaction' &&
          operationData.payment_system_id === 24 &&
          (operationData.status === 'В процессе' || operationData.status === 'Новый') && (
            <StepButton
              type={'outlined'}
              disabled={true}
              title={'Здесь будет чек'}
              handleAction={() => {
                handleClose();
              }}
            />
          )}
      </FormControl>
    </>
  );
};

export default PaymentInfo;
