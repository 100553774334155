import React from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import { Loader } from '../shared/ui/components/Loader';
import Payments from '../entities/Payments/model/Payments';

const PaymentsPage = props => (
  <PrimaryLayout>
    <main className='main'>{props.isLoading ? <Loader wrapperType='transparent' /> : <Payments />}</main>
  </PrimaryLayout>
);

export default PaymentsPage;
