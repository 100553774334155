import React, { useState } from 'react';
import { TextField, FormControl, MenuItem, Paper } from '@mui/material';
import { SelectArrow } from '../icons/core/SelectArrow';
import { styled } from '@mui/system';
import { colors, fontFamilyBase } from '../constants/styleConstants';
import { GlobalStyles } from '@mui/material';

const GlobalOverrides = () => (
  <GlobalStyles
    styles={{
      '.MuiMenuItem-root.Mui-selected': {
        backgroundColor: `${colors.workkiLightRed} !important`
      }
    }}
  />
);

const StyledFormControl = styled(FormControl)({
  'marginBottom': 16,
  '&.MuiFormControl-fullWidth:focus': {
    borderRadius: 8
  }
});

const StyledTextField = styled(TextField)({
  '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input': {
    'paddingRight': 0,
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  // .css-81ydlv-MuiButtonBase-root-MuiMenuItem-root.Mui-selected
  '& .MuiSelect-select.MuiInputBase-input': {
    'paddingRight': 0,
    'paddingBottom': 0,
    '&.MuiFocus': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiInputBase-root': {
    'padding': 0,
    'backgroundColor': 'transparent',
    '&.Mui-focused': {
      border: 'none',
      borderRadius: 8,
      backgroundColor: 'transparent'
    },
    '&.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
      'borderRadius': 8,
      'paddingRight': 0,
      'cursor': 'pointer',
      'display': 'flex',
      'alignItems': 'flex-end',
      '&::before': {
        border: 'transparent 1px solid',
        borderRadius: 8
      },
      '&::after': {
        border: '1px solid transparent',
        borderRadius: 8
      }
    }
  },
  '& .MuiInputLabel-root': {
    'paddingLeft': 0,
    '&.Mui-focused': {
      color: `${colors.workkiDarkMiddle} !important`
    },
    '&.Mui-error': {
      color: `${colors.workkiDarkMiddle} !important`
    }
  },
  '& .MuiFilledInput-root': {
    'color': colors.workkiDarkMain,
    'fontFamily': fontFamilyBase,
    'fontWeight': '500',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

const StyledMenuItem = styled(MenuItem)({
  'fontWeight': 500,
  'fontFamily': 'Comissioner, sans-serif',
  '&.Mui-selected': {
    'backgroundColor': colors.workkiLightRed,
    '&:hover': {
      backgroundColor: colors.workkiLightRed
    }
  },
  '&:focus': {
    backgroundColor: colors.workkiLightRed
  },
  '&:hover': {
    backgroundColor: colors.workkiLightLight2
  }
});

const StyledSelectArrow = styled(SelectArrow)({
  'transition': 'transform 0.3s ease',
  'transform': 'rotate(0deg)',
  '&.rotate': {
    transform: 'rotate(180deg)'
  }
});

const StyledMenuPaper = styled(Paper)({
  maxHeight: '200px',
  border: `1px solid ${colors.workkiLightLine}`,
  borderRadius: 8,
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
});

export const CustomDropdown = ({
  id,
  label,
  name,
  variant,
  value,
  onFocus,
  onBlur,
  onChange,
  placeholder,
  autoFocus,
  options,
  isFocused
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <div>
      <GlobalOverrides />
      <StyledFormControl fullWidth={true}>
        <StyledTextField
          select
          name={name}
          id={id}
          label={label}
          variant={variant}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          autoFocus={autoFocus}
          SelectProps={{
            open: menuOpen,
            onClose: handleMenuClose,
            onOpen: handleMenuOpen,
            MenuProps: {
              PaperProps: { component: StyledMenuPaper }
            },
            IconComponent: () => <StyledSelectArrow onClick={handleMenuClose} rotation={menuOpen ? '180deg' : '0deg'} />
          }}
        >
          {options.map(option => (
            <StyledMenuItem key={option.value} value={option.value}>
              {option.value !== '00' && option.value === value ? (
                <>
                  <span style={{ color: `${colors.workkiDarkMain}`, marginRight: '4px' }}>
                    {option.label.split(' ')[0]}
                  </span>
                  <span style={{ color: `${colors.workkiRedFill}` }}>{option.label.split(' ')[1]}</span>
                </>
              ) : (
                option.label
              )}
            </StyledMenuItem>
          ))}
        </StyledTextField>
      </StyledFormControl>
    </div>
  );
};
